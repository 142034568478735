/*
 * @Author: chenxing
 * @Date: 2021-08-03 16:50:27
 * @LastEditors: chenxing
 * @LastEditTime: 2022-02-24 17:22:57
 */
import moment from 'moment';

export const channelList = [
  { label: '巨量引擎', text: '巨量引擎', value: 'TT' },
  { label: '腾讯广告', text: '腾讯广告', value: 'GDT' },
  { label: '磁力引擎', text: '磁力引擎', value: 'KS' },
  { label: '巨量引擎2.0', text: '巨量引擎2.0', value: 'TT_2' },
  { label: '巨量千川', text: '巨量千川', value: 'qc' },
];
export const reportTypeList = [
    {label:"自运营报备",text:"自运营报备",value:"SELF_OPERATION"},
    {label:"走量报备",text:"走量报备",value:"INCREASE_QUANTITY"},
    {label:"收量报备",text:"收量报备",value:"INCREASE_QUANTITY_QUANTITY"},
    {label:"不报备",text:"不报备",value:"EMPTY"},
  ];

export const channelDict = { TXGG: '腾讯广告', TT: '巨量引擎', KS: '磁力引擎', QC: '巨量千川', GDT: '腾讯广告',TT_2:'巨量引擎2.0' };

export const datePicker_dateRanges = {
  今天: [moment(), moment()],
  昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
  最近七天: [moment().subtract(6, 'days'), moment()],
  本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
  上月: [
    moment()
      .subtract(1, 'month')
      .startOf('month'),
    moment()
      .subtract(1, 'month')
      .endOf('month'),
  ],
  最近三十天: [moment().subtract(29, 'days'), moment()],
};
export const industryList = [
  { label: '全行业', value: '全行业' },
  { label: '医疗', value: '医疗' },
  { label: '风险类客户', value: '风险类客户' },
  { label: '游戏类客户', value: '游戏类客户' },
  { label: '特殊投放类', value: '特殊投放类' },
  { label: '电商类客户', value: '电商类客户' },
  { label: '平台类客户', value: '平台类客户' },
  { label: '3C及电器', value: '3C及电器' },
  { label: '快速消费品', value: '快速消费品' },
  { label: '食品饮料', value: '食品饮料' },
  { label: '服装配饰', value: '服装配饰' },
  { label: '商务服务', value: '商务服务' },
  { label: '生活服务', value: '生活服务' },
  { label: '出行旅游', value: '出行旅游' },
  { label: '社会公共', value: '社会公共' },
  { label: '零售', value: '零售' },
  { label: '交通工具', value: '交通工具' },
  { label: '汽车', value: '汽车' },
  { label: '农林牧畜渔化工及能源', value: '农林牧畜渔化工及能源' },
  { label: '电子电工', value: '电子电工' },
  { label: '机械设备', value: '机械设备' },
  { label: '文体娱乐', value: '文体娱乐' },
  { label: '传媒及内容', value: '传媒及内容' },
  { label: '物流业', value: '物流业' },
  { label: '通信', value: '通信' },
  { label: '餐饮服务', value: '餐饮服务' },
  { label: '工具类软件', value: '工具类软件' },
  { label: '招商加盟', value: '招商加盟' },
  { label: '金融业', value: '金融业' },
  { label: '特殊投放业', value: '特殊投放业' },
];
export const detailIndustryList = {
  KS: [
    { label: '交通类', value: '交通类', channel: 'KS' },
    { label: '传媒资讯类', value: '传媒资讯类', channel: 'KS' },
    { label: '医疗类', value: '医疗类', channel: 'KS' },
    { label: '快手小店', value: '快手小店', channel: 'KS' },
    { label: '教育培训类', value: '教育培训类', channel: 'KS' },
    { label: '游戏类', value: '游戏类', channel: 'KS' },
    { label: '电商商家', value: '电商商家', channel: 'KS' },
    { label: '电商零售类', value: '电商零售类', channel: 'KS' },
    { label: '运动娱乐休闲类', value: '运动娱乐休闲类', channel: 'KS' },
    { label: '金融服务类', value: '金融服务类', channel: 'KS' },
  ],
  TT: [
    { label: '3C及电器', value: '3C及电器', channel: 'TT' },
    { label: '传媒及内容', value: '传媒及内容', channel: 'TT' },
    { label: '医疗', value: '医疗', channel: 'TT' },
    { label: '商务服务', value: '商务服务', channel: 'TT' },
    { label: '家居建材', value: '家居建材', channel: 'TT' },
    { label: '工具类软件', value: '工具类软件', channel: 'TT' },
    { label: '快速消费品', value: '快速消费品', channel: 'TT' },
    { label: '招商加盟', value: '招商加盟', channel: 'TT' },
    { label: '教育培训', value: '教育培训', channel: 'TT' },
    { label: '文体娱乐', value: '文体娱乐', channel: 'TT' },
    { label: '服装配饰', value: '服装配饰', channel: 'TT' },
    { label: '汽车', value: '汽车', channel: 'TT' },
    { label: '游戏', value: '游戏', channel: 'TT' },
    { label: '生活服务', value: '生活服务', channel: 'TT' },
    { label: '通信', value: '通信', channel: 'TT' },
    { label: '金融业', value: '金融业', channel: 'TT' },
    { label: '零售', value: '零售', channel: 'TT' },
    { label: '食品饮料', value: '食品饮料', channel: 'TT' },
  ],
  GDT: [
    { label: '网络资讯及社区', value: '网络资讯及社区', channel: 'GDT' },
    { label: '婚恋', value: '婚恋', channel: 'GDT' },
    { label: '在线视听与阅读', value: '在线视听与阅读', channel: 'GDT' },
    { label: '教育', value: '教育', channel: 'GDT' },
    { label: '软件工具', value: '软件工具', channel: 'GDT' },
    { label: '出版传媒', value: '出版传媒', channel: 'GDT' },
    { label: '游戏', value: '游戏', channel: 'GDT' },
    { label: '金融', value: '金融', channel: 'GDT' },
    { label: '食品', value: '食品', channel: 'GDT' },
    { label: '通讯及IT服务', value: '通讯及IT服务', channel: 'GDT' },
    { label: '商务服务', value: '商务服务', channel: 'GDT' },
    { label: '综合电商平台', value: '综合电商平台', channel: 'GDT' },
  ],
};
// 版位信息
export const siteSetOptions = [
  { value: 'SITE_SET_MOBILE_UNION', label: '优量汇', channel: 'GDT' },
  { value: 'SITE_SET_WECHAT', label: '微信公众号与小程序', channel: 'GDT' },
  { value: 'SITE_SET_MOBILE_MYAPP', label: '应用宝', channel: 'GDT' },
  { value: 'SITE_SET_MOBILE_INNER', label: 'QQ、腾讯看点、腾讯音乐', channel: 'GDT' },
  { value: 'SITE_SET_TENCENT_NEWS', label: '腾讯新闻', channel: 'GDT' },
  { value: 'SITE_SET_TENCENT_VIDEO', label: '腾讯视频', channel: 'GDT' },
  { value: 'SITE_SET_MOBILE_YYB', label: '应用宝', channel: 'GDT' },
  { value: 'SITE_SET_PCQQ', label: 'PC QQ、QQ 空间、腾讯音乐', channel: 'GDT' },
  { value: 'SITE_SET_KANDIAN', label: '腾讯看点', channel: 'GDT' },
  { value: 'SITE_SET_QQ_MUSIC_GAME', label: 'QQ、腾讯音乐及游戏', channel: 'GDT' },
  { value: 'SITE_SET_MOMENTS', label: '微信朋友圈', channel: 'GDT' },
  { value: 'SITE_SET_MINI_GAME_WECHAT', label: '微信小游戏', channel: 'GDT' },
  { value: 'SITE_SET_MINI_GAME_QQ', label: 'QQ 小游戏', channel: 'GDT' },
  { value: 'SITE_SET_MOBILE_GAME', label: 'App 游戏', channel: 'GDT' },
  { value: 'SITE_SET_QQSHOPPING', label: 'QQ 购物', channel: 'GDT' },
  { value: 'INVENTORY_FEED', label: '头条信息流', channel: 'TT' },
  { value: 'INVENTORY_VIDEO_FEED', label: '西瓜信息流', channel: 'TT' },
  { value: 'INVENTORY_HOTSOON_FEED', label: '火山信息流', channel: 'TT' },
  { value: 'INVENTORY_AWEME_FEED', label: '抖音信息流', channel: 'TT' },
  { value: 'INVENTORY_UNION_SLOT', label: '穿山甲', channel: 'TT' },
  { value: 'UNION_BOUTIQUE_GAME', label: 'ohayoo精品游戏', channel: 'TT' },
  { value: 'INVENTORY_UNION_SPLASH_SLOT', label: '穿山甲开屏广告', channel: 'TT' },
  { value: 'INVENTORY_AWEME_SEARCH', label: '搜索广告——抖音位', channel: 'TT' },
  { value: 'INVENTORY_SEARCH', label: '搜索广告——头条位', channel: 'TT' },
  { value: 'INVENTORY_UNIVERSAL', label: '通投智选', channel: 'TT' },
  { value: 'INVENTORY_BEAUTY', label: '轻颜相机', channel: 'TT' },
  { value: 'INVENTORY_PIPIXIA', label: '皮皮虾', channel: 'TT' },
  { value: 'INVENTORY_AUTOMOBILE', label: '懂车帝', channel: 'TT' },
  { value: 'INVENTORY_STUDY', label: '好好学习', channel: 'TT' },
  { value: 'INVENTORY_FACE_U', label: 'faceu', channel: 'TT' },
  { value: 'INVENTORY_TOMATO_NOVEL', label: '番茄小说', channel: 'TT' },
  { value: 'INVENTORY_FEED', label: '头条系', channel: 'TT' },
];
// 推广目标
export const promotionGoalList = [
  { value: 'PROMOTED_OBJECT_TYPE_APP_ANDROID', label: 'Android应用' },
  { value: 'PROMOTED_OBJECT_TYPE_APP_IOS', label: 'IOS 应用' },
  { value: 'PROMOTED_OBJECT_TYPE_ECOMMERCE', label: '电商推广' },
  { value: 'PROMOTED_OBJECT_TYPE_LINK_WECHAT', label: '品牌活动推广' },
  { value: 'PROMOTED_OBJECT_TYPE_APP_ANDROID_MYAPP', label: '应用宝推广' },
  { value: 'PROMOTED_OBJECT_TYPE_APP_ANDROID_UNION', label: 'Android 应用（广告包）' },
  { value: 'PROMOTED_OBJECT_TYPE_LOCAL_ADS_WECHAT', label: '本地广告（微信推广）' },
  { value: 'PROMOTED_OBJECT_TYPE_QQ_BROWSER_MINI_PROGRAM', label: 'QQ 浏览器小程序' },
  { value: 'PROMOTED_OBJECT_TYPE_LINK', label: '网页推广目标' },
  { value: 'PROMOTED_OBJECT_TYPE_QQ_MESSAGE', label: 'QQ 消息' },
  { value: 'PROMOTED_OBJECT_TYPE_QZONE_VIDEO_PAGE', label: '认证空间 - 视频说说' },
  { value: 'PROMOTED_OBJECT_TYPE_LOCAL_ADS', label: '本地广告' },
  { value: 'PROMOTED_OBJECT_TYPE_ARTICLE', label: '好文广告' },
  { value: 'PROMOTED_OBJECT_TYPE_LEAD_AD', label: '销售线索收集' },
  { value: 'PROMOTED_OBJECT_TYPE_TENCENT_KE', label: '腾讯课堂' },
  { value: 'PROMOTED_OBJECT_TYPE_QZONE_PAGE_ARTICLE', label: 'QQ 空间日志页' },
  { value: 'PROMOTED_OBJECT_TYPE_QZONE_PAGE_IFRAMED', label: 'QQ 空间嵌入页' },
  { value: 'PROMOTED_OBJECT_TYPE_QZONE_PAGE', label: 'QQ 空间首页' },
  { value: 'PROMOTED_OBJECT_TYPE_APP_PC', label: 'PC 应用' },
  { value: 'PROMOTED_OBJECT_TYPE_MINI_GAME_WECHAT', label: '微信小游戏' },
  { value: 'PROMOTED_OBJECT_TYPE_MINI_GAME_QQ', label: 'QQ 小游戏' },
  { value: 'PROMOTED_OBJECT_TYPE_WECHAT_CHANNELS', label: '微信视频号' },
];
// 素材类型
export const materialType = {
  QJJ: '情景剧',
  YSJJ: '剪辑',
  CG: '采购',
  EXTERIOR: '外部',
  INTERIOR: '内部',
  EC: '二创',
  HJ: '混剪',
  AE: 'AE',
  KHTG: '客户提供',
  WBCG: '外部采购',
  NBZZ: '内部制作',
  out: '外部',
  in: '内部',
  SCENARIST: '编导',
  FILEMAKER: '制片',
  PHOTOGRAPHER: '拍摄',
  POSTER: '后期',
  VIDEO: '视频',
  IMAGE: '图片',
};
